import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../components/Footer';
import logo from '../images/logo_inv.svg';
import { initAuth } from '../store/actions';
import { NamespaceShape } from '../store/types';
import Routes from './Routes';
import useGlobalStyles from './styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    head: {
        color: '#fff',
        display: 'flex',
        flexShrink: 0,
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        minHeight: 350,
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    title: {
        fontWeight: 700,
    },
    subtitle: {
        fontWeight: 400,
    },
    logo: {
        width: 150,
    },
    content: {
        marginTop: -theme.spacing(8),
        flex: '1 0 auto',
    },
}));

const App = () => {
    useGlobalStyles();
    const dispatch = useDispatch();
    const initLoading = useSelector((state: NamespaceShape) => state.initLoading);
    const { t } = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        dispatch(initAuth());
    }, [dispatch]);

    if (initLoading) {
        return null;
    }

    return (
        <div className={classes.root}>
            <div className={classes.head}>
                <Box mb={2}>
                    <img className={classes.logo} src={logo} alt="Plzeňský Prazdroj" />
                </Box>
                <Typography variant="h4" className={classes.title}>
                    {t('header_title')}
                </Typography>
                <Typography variant="h6" className={classes.subtitle}>
                    {t('header_subtitle')}
                </Typography>
            </div>
            <div className={classes.content}>
                <Routes />
            </div>
            <Footer />
        </div>
    );
};

export default App;
