import { Box, CircularProgress, CircularProgressProps } from '@material-ui/core';
import React from 'react';

const Loader = (props: CircularProgressProps) => {
    return (
        <Box display="flex" justifyContent="center">
            <CircularProgress size={60} {...props} />
        </Box>
    );
};

export default Loader;
