import React from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteProps } from 'react-router';
import { Redirect } from 'react-router-dom';
import { NamespaceShape } from '../store/types';

const ProtectedRoute = ({ component: Component, ...routeProps }: RouteProps) => {
    const isAuthenticated = useSelector((state: NamespaceShape) => state.isAuthenticated);

    if (!Component) {
        return null;
    }

    const render = (props: any) => {
        if (!isAuthenticated) {
            return <Redirect to={{ pathname: '/auth', state: { from: props.location } }} />;
        }

        return <Component {...routeProps} {...props} />;
    };

    return <Route {...routeProps} render={render} />;
};

export default ProtectedRoute;
