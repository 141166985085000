import { Time } from './../models/time';
import { Action, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, select, takeEvery } from 'redux-saga/effects';
import api from '../utils/api';
import { BaseDTO } from './../models/base-dto';
import { VoteDTO } from './../models/vote';
import { VoteResult } from './../models/vote-result';
import * as actions from './actions';
import { ActionTypes, NamespaceShape } from './types';

const loginApiURL = `/login.php`;
const voteApiURL = `/vote.php`;
const resultsApiURL = `/results.php`;
const timeApiURL = '/time.php';

function* loginSaga(action: PayloadAction<string>) {
    yield put(actions.loginStart());
    const { country } = yield select(({ country, code }: NamespaceShape) => ({ country, code }));

    try {
        const requestBody: BaseDTO = {
            Country: country,
            Password: action.payload,
        };
        yield api.post(loginApiURL, requestBody);

        yield localStorage.setItem('logged', btoa(action.payload));

        yield put(actions.loginSuccess(action.payload));
    } catch (error) {
        yield put(actions.loginFail());
    }
}

function* initAuthSaga() {
    try {
        const response: AxiosResponse<Time> = yield api.get(timeApiURL);
        const time = response.data;

        const now = new Date(time.Now);
        const votingAllowed = now > new Date(time.VoteFrom) && now < new Date(time.VoteTill);

        const logged = yield localStorage.getItem('logged');
        const voted = yield localStorage.getItem('voted');
        if (logged) {
            yield put(actions.initAuthDone(atob(logged), !!voted, votingAllowed));
        } else {
            yield put(actions.initAuthDone('', false, votingAllowed));
        }
    } catch (e) {}
}

function* voteSaga(action: PayloadAction<string>) {
    try {
        const { country, code } = yield select(({ country, code }: NamespaceShape) => ({ country, code }));
        const requestBody: VoteDTO = {
            Country: country,
            Password: code,
            ProjectId: action.payload,
        };
        const response: AxiosResponse<VoteResult[]> = yield api.post(voteApiURL, requestBody);

        yield localStorage.setItem('voted', Date.now() + '');

        yield put(actions.voteSuccess(response.data));
    } catch (error) {
        yield put(actions.voteFail());
    }
}

function* getResultsSaga(action: Action) {
    try {
        const { country, code } = yield select(({ country, code }: NamespaceShape) => ({ country, code }));
        const requestBody: BaseDTO = {
            Country: country,
            Password: code,
        };
        const response: AxiosResponse<VoteResult[]> = yield api.post(resultsApiURL, requestBody);

        yield put(actions.getResultsSuccess(response.data));
    } catch (error) {
        yield put(actions.voteFail());
    }
}

export function* watch() {
    yield takeEvery(ActionTypes.INIT, initAuthSaga);
    yield takeEvery(ActionTypes.LOGIN, loginSaga);
    yield takeEvery(ActionTypes.VOTE, voteSaga);
    yield takeEvery(ActionTypes.GET_RESULTS, getResultsSaga);
}
