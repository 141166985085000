import { createAction } from '@reduxjs/toolkit';
import { VoteResult } from './../models/vote-result';
import { ActionTypes } from './types';

export const login = createAction<string>(ActionTypes.LOGIN);

export const loginStart = createAction(ActionTypes.LOGIN_START);
export const loginSuccess = createAction<string>(ActionTypes.LOGIN_SUCCESS);

export const loginFail = () => {
    return {
        type: ActionTypes.LOGIN_FAIL,
    };
};

export const initAuth = createAction(ActionTypes.INIT);
export const initAuthDone = createAction(ActionTypes.INIT_DONE, (logged: string, voted: boolean, votingAllowed: boolean) => ({
    payload: {
        logged,
        voted,
        votingAllowed,
    },
}));

export const vote = createAction<string>(ActionTypes.VOTE);
export const voteSuccess = createAction<VoteResult[]>(ActionTypes.VOTE_SUCCESS);
export const voteFail = createAction(ActionTypes.VOTE_FAIL);

export const getResults = createAction(ActionTypes.GET_RESULTS);
export const getResultsSuccess = createAction<VoteResult[]>(ActionTypes.GET_RESULTS_SUCCESS);
