import { Avatar, Button, CircularProgress, Container, Grid, Link, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router';
import { DefaultLocationState } from '../models/location';
import { login } from '../store/actions';
import clsx from 'clsx';
import { NamespaceShape } from '../store/types';
import logo from '../images/logo_inv.svg';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -10,
        marginLeft: -10,
    },
    relative: {
        position: 'relative',
    },
}));

const Authorize = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();

    const [code, setCode] = useState('');

    const isAuthenticated = useSelector((state: NamespaceShape) => state.isAuthenticated);
    const loading = useSelector((state: NamespaceShape) => state.loading);
    const error = useSelector((state: NamespaceShape) => state.error);

    const { from }: DefaultLocationState = (location.state as DefaultLocationState) || { from: { pathname: '/' } };

    const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCode(event.target.value);
    };

    const handleSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(login(code));
    };

    if (isAuthenticated) {
        return <Redirect to={from} />;
    }

    return (
        <React.Fragment>
            <Container component="main" maxWidth="xs">
                <Paper className="paper paperTop">
                    {/* <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar> */}
                    <Typography component="h1" variant="h5">
                        {t('log_in')}
                    </Typography>
                    <form className={classes.form} onSubmit={handleSubmit} noValidate>
                        <TextField
                            margin="normal"
                            required
                            type="password"
                            fullWidth
                            value={code}
                            onChange={handleCodeChange}
                            id="code"
                            label={t('code')}
                            name="code"
                            autoComplete="off"
                            autoFocus
                        />

                        {error && <Alert severity="error">{t('login_error')}</Alert>}

                        <div className={clsx(classes.relative, classes.submit)}>
                            <Button type="submit" fullWidth variant="contained" color="primary" disabled={loading}>
                                {t('enter')}
                            </Button>
                            {loading && <CircularProgress size={20} className={classes.buttonProgress} />}
                        </div>
                    </form>
                </Paper>
            </Container>
        </React.Fragment>
    );
};

export default Authorize;
