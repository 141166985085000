import { VoteResult } from './../models/vote-result';
export type NamespaceShape = {
    code: string;
    isAuthenticated: boolean;
    error: boolean;
    loading: boolean;
    voteLoading: boolean;
    initLoading: boolean;
    country: string;
    voted: boolean;
    voteResults: VoteResult[];
    votingAllowed: boolean;
};

export enum ActionTypes {
    LOGIN = '@@root/LOGIN',
    LOGIN_START = '@@root/LOGIN_START',
    LOGIN_SUCCESS = '@@root/LOGIN_SUCCESS',
    LOGIN_FAIL = '@@root/LOGIN_FAIL',
    INIT = '@@root/INIT',
    INIT_START = '@@root/INIT_START',
    INIT_DONE = '@@root/INIT_DONE',
    VOTE = '@@root/VOTE',
    VOTE_SUCCESS = '@@root/VOTE_SUCCESS',
    VOTE_FAIL = '@@root/VOTE_FAIL',
    GET_RESULTS = '@@root/GET_RESULTS',
    GET_RESULTS_SUCCESS = '@@root/GET_RESULTS_SUCCESS',
}

export interface InitAuthDonePayload {
    voted: boolean;
    logged: string;
    votingAllowed: boolean;
}
