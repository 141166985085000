import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { parseCountry } from '../utils/parse-country';

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'cs',
        lng: parseCountry(),
        debug: process.env.NODE_ENV === 'development',
        nonExplicitSupportedLngs: true,
        react: {
            transSupportBasicHtmlNodes: true,
        },
    });

export default i18n;
