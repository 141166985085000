import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';
import type from '@material-ui/lab/themeAugmentation';

// A custom theme for this app
const theme = createMuiTheme({
    typography: {
        fontFamily: ['Forma Pro', 'Arial', 'sans-serif'].join(','),
        body1: {
            lineHeight: 1.6,
        },
    },
    props: {
        MuiTextField: {
            variant: 'filled',
        },
        MuiSelect: {
            variant: 'filled',
        },
    },
    palette: {
        // type: 'dark',
        primary: {
            main: '#9f8852',
        },
        secondary: {
            main: '#c6243e',
        },
        error: {
            main: red.A400,
        },
        background: {
            // default: '#dbcf9a',
            default: '#fff',
        },
        text: {
            primary: '#000',
        },
    },
});

export default theme;
