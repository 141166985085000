import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <Box textAlign="center" p={2} pt={4}>
            <Typography color="textSecondary" variant="body2">
                {t('copyright')}
            </Typography>
        </Box>
    );
};

export default Footer;
