import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Loader from '../components/Loader';
import ProtectedRoute from '../components/ProtectedRoute';
import Authorize from '../containers/Authorize';

const Projects = React.lazy(() => import('../containers/Projects'));
const ThankYou = React.lazy(() => import('../containers/ThankYou'));

const Routes = () => {
    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                <Route path="/auth" exact component={Authorize} />
                <ProtectedRoute path="/thank-you" exact component={ThankYou} />
                <ProtectedRoute path="/" exact component={Projects} />
                <Redirect to="/" />
            </Switch>
        </Suspense>
    );
};

export default Routes;
