import axios, { AxiosInstance } from 'axios';

const API_BASE_URL = 'https://plzenskyprazdroj.mytootoot.com/api';

const api: AxiosInstance = axios.create({
    baseURL: API_BASE_URL,
});

export function getUrlByFragments(...fragments: string[]): string {
    return [API_BASE_URL, ...fragments].join('/');
}

export default api;
