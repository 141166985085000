import { makeStyles } from '@material-ui/core';

const useGlobalStyles = makeStyles((theme) => ({
    '@global': {
        '.paper': {
            boxShadow: theme.shadows[2],
            padding: theme.spacing(2),
            borderRadius: theme.shape.borderRadius,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        '.paperTop': {
            paddingTop: theme.spacing(8),
        },
    },
}));

export default useGlobalStyles;
