import { Action, PayloadAction } from '@reduxjs/toolkit';
import { VoteResult } from '../models/vote-result';
import { createReducer } from '../utils/create-reducer';
import { parseCountry } from '../utils/parse-country';
import { ActionTypes, InitAuthDonePayload, NamespaceShape } from './types';

const initAuthDone = (state: NamespaceShape, action: PayloadAction<InitAuthDonePayload>) => ({
    ...state,
    isAuthenticated: Boolean(action.payload.logged),
    code: action.payload.logged,
    initLoading: false,
    voted: action.payload.voted,
    votingAllowed: action.payload.votingAllowed,
});

const authStart = (state: NamespaceShape, action: Action) => {
    return {
        ...state,
        error: false,
        loading: true,
    };
};

const authSuccess = (state: NamespaceShape, action: PayloadAction<string>) => {
    return {
        ...state,
        code: action.payload,
        loading: false,
        isAuthenticated: true,
    };
};

const authFail = (state: NamespaceShape, action: Action) => {
    return {
        ...state,
        error: true,
        code: '',
        loading: false,
    };
};

const vote = (state: NamespaceShape, action: PayloadAction<string>) => {
    return {
        ...state,
        voteLoading: true,
    };
};

const voteSuccess = (state: NamespaceShape, action: PayloadAction<VoteResult[]>) => {
    return {
        ...state,
        voted: true,
        voteLoading: false,
        results: [...action.payload],
    };
};

const voteFail = (state: NamespaceShape, action: Action) => {
    return {
        ...state,
        error: true,
        voteLoading: false,
    };
};

const getResults = (state: NamespaceShape, action: PayloadAction<VoteResult[]>) => {
    return {
        ...state,
        voteLoading: true,
    };
};

const getResultsSuccess = (state: NamespaceShape, action: PayloadAction<VoteResult[]>) => {
    return {
        ...state,
        voteLoading: false,
        voteResults: action.payload,
    };
};

const initialState: NamespaceShape = {
    country: parseCountry(),
    code: '',
    error: false,
    loading: false,
    initLoading: true,
    voteLoading: false,
    isAuthenticated: false,
    voted: false,
    voteResults: [],
    votingAllowed: false,
};

const reducerMap = {
    [ActionTypes.LOGIN_START]: authStart,
    [ActionTypes.LOGIN_SUCCESS]: authSuccess,
    [ActionTypes.LOGIN_FAIL]: authFail,
    [ActionTypes.INIT_DONE]: initAuthDone,
    [ActionTypes.VOTE]: vote,
    [ActionTypes.VOTE_SUCCESS]: voteSuccess,
    [ActionTypes.VOTE_FAIL]: voteFail,
    [ActionTypes.GET_RESULTS]: getResults,
    [ActionTypes.GET_RESULTS_SUCCESS]: getResultsSuccess,
};

export default createReducer<NamespaceShape>(initialState, reducerMap);
